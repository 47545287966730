import React from 'react';
import styles from './styles.module.scss';
import MagiclinkComponent from './MagiclinkComponent';
import Loader from '@/components/ui/Loader';
import useTemplateData from '@/components/landing-page-templates/useTemplateData';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@/models/account';
import Image from '@/components/ui/media/Image';
import { makeSelectEventById } from '@/models/event';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { IMAGE_URL, ZUDDL_LOGO_COLORED } from '@/config';
import { getPageMetadataComp } from '@/utils/metadata';

const MagicLinkPage = props => {
  const {
    isSpeakerPage,
  } = props;
  const { eventId } = useParams<{ eventId: string }>();
  const currentUser = useSelector(selectCurrentUser);
  const templateData = useTemplateData(eventId);
  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const eventLogo =
    event && event.hasOwnProperty("logoUrl") ? event.logoUrl !== `${IMAGE_URL}` && !!event.logoUrl
      ? event.logoUrl
      : ZUDDL_LOGO_COLORED : null;

  const getLoader = () => {
    return (
              <div className={styles.loadingContainer}>
                  {eventLogo && <div className={styles.imageContainer}><Image src={eventLogo} /></div>}
                  {getPageMetadataComp({ eventId })}
                  <div className={styles.loaderContainer}>
                    <Loader />
                    <div className={styles.loadingText}>Loading, please wait...</div>
                  </div>
              </div>
            );
  }

  if(!templateData?.templateDetails?.eventLandingPageId) {
    return <div className={styles.container}>
              {getLoader()}
            </div>;
  }

  return (
    <div className={styles.container}>
      {getLoader()}
      {getPageMetadataComp({ eventId })}
      <MagiclinkComponent
        currentUser={currentUser}
        landingPageObject={templateData?.templateDetails}
        isSpeakerPage={isSpeakerPage}
      />
    </div>
  );

};


export default MagicLinkPage;
