import React from 'react';
import Helmet from 'react-helmet';

interface PageMetadata {
  title?: string;
  favicon?: string;
}

const getPageMetadata = ({ eventId }): PageMetadata | null => {
  // [Prod] - Gallup
  if (
    [
      'b985f5d2-22e7-44c2-b2c8-f23306629719',
      '1303b7a3-74a8-4f96-a1f5-764ce2a056fe',
    ].includes(eventId)
  ) {
    return {
      title: 'Gallup',
      favicon:
        'https://irp.cdn-website.com/dd581781/site_favicon_16_1737577196207.ico',
    };
  }
  // Testing on Pre-prod
  if (eventId === 'df12d762-7919-4634-aa86-a70d6511c013') {
    return {
      title: 'Testing page title',
      favicon:
        'https://irp.cdn-website.com/dd581781/site_favicon_16_1737577196207.ico',
    };
  }

  return null;
};

export const getPageMetadataComp = ({ eventId }) => {
  const meta = getPageMetadata({ eventId });

  if (!meta) return null;

  const { title, favicon } = meta;

  return (
    <>
      {!!title && (
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
      )}
      {!!favicon && (
        <Helmet>
          <link id="favicon" rel="icon" type="image/x-icon" href={favicon} />
        </Helmet>
      )}
    </>
  );
};
